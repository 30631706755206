import oucy from "@/util/oucyUtil";

export default {
    name: "Enter",
    data() {
        return {
            pageWidth: oucy.pageWidth,
        }
    },
    methods: {
        
    }
}