<template>
    <div>
         <div class="container m-t-40 p-t-60">
            <div class="pageCenter" :style="{width: pageWidth+'px'}">
                <el-container>
                    <div style="padding: 0 0 50px;width: 100%">
                        <h3>支付结果</h3>
                        <!-- 有货 -->
                        <div class="content p-t-50 p-b-50">
                            <div class="text-center " style="margin-top:86px">
                                <img src="@/assets/images/stockCar/succeed.png">
                                <div class="finishOrder m-t-30">恭喜，您已支付完成!!</div>
                                <div class="f14 m-t-15">您已完成支付，系统已为您生成交易记录！</div>
                                <el-button class="m-t-30" @click="$oucy.back()">返回</el-button>
                            </div>
                        </div>
                    </div>
                </el-container>
            </div>
        </div>
    </div>
</template>
<script>
import finish from './finish.js'
export default finish
</script>
<style lang="less" scoped>
@import "./finish.less";
</style>